<template>
  <v-card-text class="py-0">
    <v-timeline align-top dense>
      <v-timeline-item
        v-for="(event, i) in events"
        :key="i"
        small
      >
        <v-row class="pt-1">
          <v-col cols="3">
            <strong>{{ event.time }}</strong>
          </v-col>
          <v-col>
            <strong>{{ event.title }}</strong>
            <div class="text-caption">
              {{ event.description }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </v-card-text>
</template>

<script>
export default {
  name: "Timeline",

  data: () => ({
    events: [
      {
        time: "10:00",
        title: "Ontvangst daggasten",
        description: "",
      },
      {
        time: "10:30",
        title: "Ceremonie",
        description: "",
      },
      {
        time: "12:30",
        title: "Lunch",
        description: "",
      },   
      {
        time: "15:45",
        title: "Ontvangst avondgasten",
        description: "",
      },
      {
        time: "16:15",
        title: "Taart aansnijden",
        description: "",
      },
      {
        time: "20:00",
        title: "Feest!",
        description: "DJ",
      },
      {
        time: "00:00",
        title: "DJ gaat naar huis",
        description: "",
      },         
    ],  
  }),
};
</script>
